/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "ngx-toastr/toastr";

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@font-face {
  font-family: "Axiforma";
  src: url("../src/assets/fonts/axiforma-regular.otf") format("opentype");
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url("../src/assets/fonts/Axiforma-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma-Light";
  src: url("../src/assets/fonts/Axiforma-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma-Medium";
  src: url("../src/assets/fonts/Axiforma-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma-Regular";
  src: url("../src/assets/fonts/Axiforma-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma-SemiBold";
  src: url("../src/assets/fonts/Axiforma-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma-Thin";
  src: url("../src/assets/fonts/Axiforma-Thin.woff2") format("woff2");
}

select:focus {
  outline: none !important;
  box-shadow: none !important;
}

option:focus {
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus,
input:focus {
  box-shadow: 0 0 0 0 !important;
  outline: none !important;
}

.background-app {
  background-image: url(./assets/images/bg-main-white.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

:root {
  --green: #226184;
  --darkgreen: #1040ED;
  --purple: #542fdf;
  --black: #000;
  --primary: #226184;
  --support-info: #299BDB;
  --primary-auxiliar: #003F62;
  --primary-clear: #6855f3;
  --text-primary: #020202;
  --text-secundary: #4C4C4C;
  --support-lines: #AEB0BB;
  --support-cinza-16: rgba(174, 176, 187, 0.16);
  --gray: #888888;
  --clear-gray: #f5f4f4;
  --darkprimary: #003F62;
  --blue: #299bdb;
  --red: #ee5252;
  --white: #ffffff;
  --strong-blue: #1040ED;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-800 {
  font-family: "Axiforma-Bold";
}

.fw-700 {
  font-family: "Axiforma-SemiBold";
}

.fw-600 {
  font-family: "Axiforma-Regular";
}

.fw-500 {
  font-family: "Axiforma-Medium";
}

.fw-400 {
  font-family: "Axiforma-Light";
}

// .fw-400 {
//   font-family: "Axiforma-Thin";
// }

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 1rem;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-35 {
  font-size: 35px;
}

.line-15 {
  line-height: 15px !important;
}

.line-16 {
  line-height: 16px !important;
}

.line-19 {
  line-height: 19px !important;
}

.line-20 {
  line-height: 20px !important;
}

.line-24 {
  line-height: 24px !important;
}

.line-26 {
  line-height: 26px !important;
}

.line-32 {
  line-height: 32px !important;
}

.line-1242 {
  line-height: 1.2425rem !important;
}

.letter-spacing-05 {
  letter-spacing: 0.5px;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-0016 {
  letter-spacing: 0.01681rem;
}

.letter-spacing-0014 {
  letter-spacing: 0.01469rem;
}

.letter-spacing-005 {
  letter-spacing: 0.0515rem;
}

.letter-spacing-0098 {
  letter-spacing: 0.03988em;
}

.letter-spacing-0020 {
  letter-spacing: 0.02519rem;
}

.letter-spacing-080 {
  letter-spacing: 0.08063rem;
}

.letter-spacing-2019 {
  letter-spacing: 0.02019rem;
}

// Backgrounds
.bg-green {
  background: var(--green) !important;
}

.bg-gray {
  background: var(--gray) !important;
}

.bg-white {
  background: var(--white) !important;
}

.bg-transparent {
  background: transparent;
}

.bg-blue {
  background: #2a446b !important;
}

.modal-content {
  background-color: transparent !important;
}

.bg-modal {
  background-color: var(--clear-gray) !important;
  border-radius: 1rem;
}


// Font Color
.color-primary {
  color: var(--primary);
}

.color-support-info {
  color: var(--support-info);
}

.color-secundary {
  color: var(--text-secundary);
}

.color-black {
  color: var(--text-primary) !important;
}

.color-gray {
  color: var(--gray);
}

.color-lightgray {
  color: var(--support-lines);
}

.color-blue {
  color: var(--blue);
}

.color-red {
  color: var(--red);
}

.color-white {
  color: white;
}

.color-darkprimary {
  color: var(--primary-auxiliar);
}

.color-lightprimary {
  color: #8BA78F;
}

.color-strong {
  color: #1040ED;
}

.color-grayfort {
  color: #4C4C4C;
}
// Border
.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-code {
  border: 1.5px solid #aeb0bb;
}

.border-dark-green {
  border: 1px solid #364a3d !important;
}

.border-green {
  border: 1px solid #226184 !important;
}

.border-none {
  border: none;
}

.h-37 {
  height: 37px !important;
}

.w56h56 {
  width: 56px;
  height: 56px;
}

.w46h46 {
  width: 46px;
  height: 46px;
}

.width-86 {
  width: 86%;
}

.min-width-70 {
  min-width: 70px;
}

.icon-width-24 {
  width: 24px;
}

.margin-right-118 {
  margin-right: 118px;
}

a {
  text-decoration: none;
}

// Inputs
.input-transparent-darkgreen {
  background: transparent;
  border: 1px solid var(--darkgreen);
  border-radius: 8px;
  height: 56px;
  padding: 16px;
  color: #4C4C4C;
}

.input-transparent {
  background: transparent;
  border: 1px solid var(--green);
  border-radius: 8px;
  height: 56px;
  padding: 9px;
  color: #4C4C4C;
}

.input-transparent-login-number {
  background: transparent;
  border: 1px solid var(--green);
  border-radius: 8px;
  height: 56px;
  padding: 9px;
  color: #4C4C4C !important;
  display: flex;
  align-items: center;
}

.input-transparent-login-number-red {
  background: transparent;
  border: 1px solid var(--red);
  border-radius: 8px;
  height: 56px;
  padding: 9px;
  color: #4C4C4C !important;
  display: flex;
  align-items: center;
}

.border-red {
  border: 1px solid var(--red);
}

input::placeholder {
  color: var(--gray);
  font-size: 14px !important;
}

input[type="tel"],
input[type="tel"]::placeholder {
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: 0.01681rem;
}

button[disabled] {
  opacity: 0.5;
  // cursor: not-allowed;
}

.button-green, .button-white {
  border-radius: 8px !important;
  border: none;
  width: 100%;
  height: 48px;
  letter-spacing: 1px;
  transition: 0.3s ease;
}

.button-green {
  background: var(--green) !important;
  color: white;
}

.button-white {
  background: #F0F1F3 !important;
  color: var(--black);
}

.button-green-home {
  background: var(--green) !important;
  border-radius: 0px 0px 8px 8px !important;
  border: none;
  color: white;
  width: 100%;
  height: 48px;
  transition: .3s ease;
}

.br-6 {
  border-radius: .5rem;
}

.button-outline-green {
  background: transparent !important;
  border-radius: 8px !important;
  border: 1px solid var(--green) !important;
  color: var(--green);
  width: 100%;
  height: 48px;
  letter-spacing: 1px;
}

.toolbar-background {
  border-radius: 40px;
  background: rgba(46, 48, 58);
  height: 66px;
}

.card-home,
.button-card-home {
  border-radius: 10px;
  background: #aeb0bb29;
  transition: 0.3s ease;
}

.new-card-home {
  border-radius: 10px 10px 0px 0px;
  background: #AEB0BB29;
  margin-bottom: -8px;
}

.card-home-blue {
  border-radius: 10px;
  background: #2a446b;
  transition: 0.3s ease;
}

.card-home-green {
  border-radius: 10px;
  background: #226184;
}

// .cards-container-home {
//   width: 50%;
// }

.cards-container-home {
  width: 100%;
}

.button-card-home {
  width: calc((100% - 1rem) / 3);
  height: calc((94vw - 1rem) / 3) 142px;
}

.buttons-container-home {
  gap: 0.5rem;
}

@media screen and (min-width: 992px) {
  .button-card-home {
    width: 31%;
    height: 117px;
  }

  .cards-container-home {
    width: 100%;
  }

  .cards-container-home-2 {
    margin-top: 0.75rem !important;
  }

  .buttons-container-home {
    gap: 0.75rem;
  }
}

.card-add-money {
  border-radius: 8px;
  background: #aeb0bb29;
  // height: 56px;
  transition: .3s;
}

.check-input-container {
  width: 3.5625rem;
  height: 1.75rem;
  background-color: rgba(174, 176, 187, 0.16);
  border-radius: 100px;

  .check-input {
    width: 20px;
    height: 20px;
    background-color: rgb(174, 176, 187);
    border-radius: 50%;
    vertical-align: middle;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    transition: 0.35s ease;

    &:checked {
      background-color:#226184;
      outline: none;
      transform: translateX(142%);
    }
  }
}

// Profile
.border-profile-picture {
  max-height: 1.8rem !important;
  max-width: 1.8rem !important;
  border-radius: 50% !important;
  border: 2px solid #01ff5f !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.cam-change-picture-circle {
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
  background-color: white !important;
  height: 28px !important;
  width: 28px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.photo-profile-custom {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50% !important;
  background: var(--green);
}

.photo-profile-my-account {
  height: 6.8125rem !important;
  width: 6.8125rem !important;
  border-radius: 50% !important;
  background: var(--green);
}

.input-textarea-custom {
  background: rgba(174, 176, 187, 0.16) !important;
  border: 1px solid #364a3d !important;
  color: #FBFBFB !important;

  &::placeholder {
    color: #7f808a;
  }
}

.btn-black-custom {
  background-color: #232323 !important;
  border: none !important;
  color: white !important;
  font-weight: bold !important;
}

.btn-select-coin-deposit {
  background-color: #aeb0bb29 !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 8px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  min-width: 100% !important;
}

.text-purple {
  color: #adaec1 !important;
}

.text-green {
  color: #226184 !important;
}

.bg-demi-transparent {
  background-color: rgba(174, 176, 187, 0.16) !important;
}

.bw-2 {
  border-width: 2px !important;
}

.btn-deposit-coin-bottom {
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  border: 1px solid #226184 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn-recue-BitbentzCoin {
  border: 1px solid var(--green);
  background: #2e2e30;
  border-radius: 10px;
}

// tamanho icons perfil
.width-icon {
  width: 10px !important;
}

hr {
  height: 1px;
  color: var(--gray);
}

// Input login-number
.iti input,
.iti input[type="tel"] {
  background: transparent;
  border: none;
  color: #060A30;
}

#country-search-box {
  color: black;
}

.iti__country-list {
  max-height: 160px !important;
  max-width: 280px !important;
}

// Scroll
.scroll-x {
  max-width: 550px;
  overflow-x: scroll;
}

.card-validation-id-custom {
  border: 1px dashed #226184 !important;
  background-color: #aeb0bb29 !important;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rounded-check-error {
  position: absolute !important;
  bottom: -20px !important;
  right: 15px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  background-color: #ee5252 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rounded-check-success {
  position: absolute !important;
  bottom: -20px !important;
  right: 15px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  background-color: #3fdc81 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn-cicle-fake-photo {
  height: 79px !important;
  width: 79px !important;
  border-radius: 50% !important;
  border: 3px solid white !important;
  padding: 4px !important;
}

.div-fake-center-button-take-photo {
  background-color: white !important;
  min-height: 100% !important;
  min-width: 100% !important;
  border-radius: 50% !important;
}

.card-validation-id-photo-custom {
  width: 358px !important;
  height: 484px !important;
  border: 2px solid #aeb0bb !important;
  background-color: #aeb0bb29 !important;
  border-radius: 20px !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #aeb0bb;
}

::-webkit-scrollbar-thumb {
  background: #226184 !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@media (max-width: 516px) {
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.max-width-576 {
  max-width: 576px;
}

// Skeleton-loading

@keyframes skeleton {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}

.skeleton-container {
  background-color: #eaecf029;
  border-radius: 6px;

  animation-duration: 0.8s;
  animation-name: skeleton;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

br-

.height-25 {
  height: 25px;
}

.height-21 {
  height: 21px;
}

// Css calendário
.bs-datepicker-container {
  background: #22232E !important;
}

.bs-datepicker-head {
  background: none !important;
}

.bs-datepicker-body, .table.days {
  border: none !important;
}

[ngbDatepickerDayView], 
ngb-datepicker-navigation-select>.form-select{
  color: white !important;
}

.ngb-dp-weekday,
.ngb-dp-arrow-next,
.ngb-dp-navigation-chevron {
  color: var(--green) !important;
}

.ngb-dp-months, 
.ngb-dp-month:first-child 
.ngb-dp-week,.ngb-dp-header,
ngb-datepicker-navigation-select>.form-select {
  background: #22232E !important;
}

.bg-primary {
  background-color:#226184 !important;
  color: black !important;
}
// 

// Animations
.fade-in-left {
  animation-name: fadeInLeft;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    translate: -.8rem;
  }
  100% {
    opacity: 1;
    translate: 0;
  }
}

.fade-in-right {
  animation-name: fadeInRight;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    translate: .8rem;
  }
  100% {
    opacity: 1;
    translate: 0;
  }
}

.fade-in-bottom {
  animation-name: fadeInBottom;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-timing-function: ease-out;
  opacity: 0;
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    translate: 0 .8rem;
  }
  100% {
    opacity: 1;
    translate: 0;
  }
}

.fade-in-top {
  animation-name: fadeInTop;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-timing-function: ease-out;
  opacity: 0;
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    translate: 0 -.8rem;
  }
  100% {
    opacity: 1;
    translate: 0;
  }
}

.fade-in{
  animation-name: fadeIn;
  animation-duration: .7s;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-timing-function: ease-out;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

video, .webcam-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.qrcode {
  border-radius: 10px !important;
  overflow: hidden;
  max-height: 225px;
}

.background-rocket {
  background-color: transparent;
  background-image: url('../src/assets/icon/icon-foguete.svg');
  background-size: cover;
  background-position: top, center;

  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;
}

input[type="radio"]{
  background-color: transparent;
  border-color:#226184;
  width: 23px;
  height: 23px;
}
input[type="radio"]:checked{
  background-color:#226184;
  color: black;
  border: none;
}

@media screen and (min-width: 800px) {
  .background-rocket {
    height: 100% !important;
  }
}

section.container {
  &.min-vh-100 {
    overflow-x: hidden !important;
  }
}

.new-message-icon {
  background-color: #226184;
  height: 8px;
  width: 8px;
  border-radius: 5px;
  border: none;
  top: 1.1rem;
  right: 1.2rem;
}